<template>
  <v-container>
    <v-layout row wrap>
      <v-flex xs6 offset-xs3>
        <h1>Register</h1>
        <v-text-field
          label="Email"
          placeholder="Please enter your email"
          :value="registerEmail"
          @input="setRegisterEmail"
          :error-messages="registerEmailError">
        </v-text-field>

        <v-text-field
          label="Password"
          placeholder="Please enter your password"
          type="password"
          :value="registerPassword"
          @input="setRegisterPassword"
          :error-messages="registerPasswordError">
        </v-text-field>

        <v-text-field
          label="Confirm Password"
          placeholder="Please confirm your password"
          type="password"
          :value="registerConfirmPassword"
          @input="setRegisterConfirmPassword"
          :error-messages="registerConfirmPasswordError">
        </v-text-field>

        <v-alert type="error" :value="!!registerError">{{registerError}}</v-alert>

        <v-btn color="green" dark @click="register"><v-icon>mdi-account-circle</v-icon>Register</v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  computed: {
    ...mapState('authentication', [
      'registerEmail',
      'registerPassword',
      'registerConfirmPassword',
      'registerError',
      'registerEmailError',
      'registerPasswordError',
      'registerConfirmPasswordError'
    ]),
  },
  methods: {
    ...mapMutations('authentication', [
      'setRegisterEmail',
      'setRegisterPassword',
      'setRegisterConfirmPassword',
    ]),
    ...mapActions('authentication', [
      'register',
    ]),
  },
};
</script>

<style>

</style>
