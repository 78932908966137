<template>
  <v-container>
    <v-layout class="mb-8">
      <v-row class="">
        <SuccessMsg :msg="createSuccess"></SuccessMsg>
        <v-col cols="12" class="d-flex justify-end">
          <v-btn class="" color="blue" dark :to="{ name: 'AddTour' }">
            Add Tour
          </v-btn>
        </v-col>
      </v-row>
    </v-layout>
    <v-layout>
      <v-row>
        <Tours></Tours>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
import Tours from "@/components/Tours.vue";
import SuccessMsg from "@/components/SuccessMsg.vue";
import { mapState, mapMutations } from "vuex";

export default {
  name: "tours",
  components: {
    Tours,
    SuccessMsg
  },
  computed: {
    ...mapState("tours", ["createSuccess"])
  },
  methods: {
    ...mapMutations("tours", ["clearCreateSuccess"])
  },
  created() {
    setTimeout(() => {
      this.clearCreateSuccess();
    }, 5000);
  }
};
</script>
