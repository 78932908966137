import HTTP from "../http";

class UploadImageService {
  upload(file, location, onUploadProgress) {
    let formData = new FormData();

    formData.append("image", file);

    return HTTP().post(`${location}/upload_image`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress
    })
  }
}


export default new UploadImageService();
