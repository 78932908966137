<template>
  <v-container>
    <v-row>
      <v-col sm="6" offset-sm="3">
        <h1>Create</h1>
        <v-text-field
          label="Story Name"
          placeholder="Please enter Story Name"
          :value="currentStory['name']"
          @input="setStoryDetails([$event, 'name'])"
          :error-messages="errorBag['name']"
        ></v-text-field>
        <v-text-field
          label="Story Description"
          placeholder="Please enter a short description"
          :value="currentStory['description']"
          @input="setStoryDetails([$event, 'description'])"
          :error-messages="errorBag['description']"
        ></v-text-field>
        <v-text-field
          label="Latitude"
          placeholder="Please enter the latitude"
          :value="currentStory['latitude']"
          @input="setStoryDetails([$event, 'latitude'])"
          :error-messages="errorBag['latitude']"
          @keypress="isNumber($event)"
        ></v-text-field>
        <v-text-field
          label="Longitude"
          placeholder="Please enter longitude"
          :value="currentStory['longitude']"
          @input="setStoryDetails([$event, 'longitude'])"
          :error-messages="errorBag['longitude']"
          @keypress="isNumber($event)"
        ></v-text-field>
        <v-text-field
          label="Genre"
          placeholder="Please enter story genre"
          :value="currentStory['genre']"
          @input="setStoryDetails([$event, 'genre'])"
          :error-messages="errorBag['genre']"
        ></v-text-field>
        <v-text-field
          label="Voice Actor"
          placeholder="Please enter the voice actor"
          :value="currentStory['voice_actor']"
          @input="setStoryDetails([$event, 'voice_actor'])"
          :error-messages="errorBag['voice_actor']"
        ></v-text-field>

        <UploadImage label="Add Image"></UploadImage>

        <v-alert type="error" :value="!!createError"
          ><span v-html="createError"> </span
        ></v-alert>
        <v-btn color="green" dark @click="createStoryAndUpload"
          ><v-icon>mdi-plus</v-icon>Create</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import router from "../router";

export default {
  computed: {
    ...mapState("stories", ["currentStory", "createError", "errorBag"])
  },
  methods: {
    ...mapMutations("stories", [
      "setStoryDetails",
      "setImageOnStory",
      "setCreateError"
    ]),
    ...mapMutations("uploadImage", ["setErrorMsg"]),
    ...mapMutations("stories", ["clearCurrentStory"]),
    ...mapActions("stories", ["createStory"]),
    ...mapActions("uploadImage", ["uploadImage"]),

    createStoryAndUpload() {
      this.createStory()
        .then(d => {
          this.uploadImage(d["_links"].self)
            .then(() => {
              this.clearCurrentStory();
              router.push("/stories/");
            })
            .catch(e => {
              this.setErrorMsg(e.response.data);
              router.push(`/stories/uploadImage`);
            });
        })
        .catch(e => {
          console.log(e.response);
          this.setCreateError(e.response.data);
        });
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46 &&
        charCode !== 45
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  }
};
</script>
