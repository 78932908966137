import HTTP from "../http";

export default {
  namespaced: true,
  state: {
    tour: {},
    errorBag: {},
    tours: [],
    deletedMsg: "",
    createError: "",
    createSuccess: ""
  },
  actions: {
    createTour({ commit, state }) {
      if (state.tour["tags"]) {
        state.tour["metaData"] = { tags: state.tour["tags"].split(",") };
      }
      return HTTP()
        .post("/v1/tours/", state.tour)
        .then(({ data }) => {
          commit("appendTour", data);
          return data;
        });
    },
    getTours({ commit }) {
      HTTP()
        .get("/v1/tours/")
        .then(({ data }) => {
          commit("setTours", data.results);
        });
    },
    deleteTour({ commit }, tour) {
      HTTP()
        .delete(`/v1/tours/${tour}`)
        .then(() => {
          commit("removeTour", tour);
        });
    }
  },
  mutations: {
    setTours(state, tours) {
      state.tours = tours;
    },
    removeTour(state, tour) {
      state.deletedMsg = `${tour.name} deleted`;
      state.tours.splice(
        state.tours.findIndex(x => x.uuid === tour),
        1
      );
    },
    setTourDetails(state, details) {
      state.tour[details[1]] = details[0];
    },
    setCreateError(state, error) {
      state.errorBag = error;
      state.createError =
        "There was an error creating the tour. Please check all fields were filled out";
    },
    appendTour(state, tour) {
      state.tours.push(tour);
      state.createSuccess = "New tour created!";
      state.errorBag = {};
      state.createError = "";
      state.tour = tour;
    },
    setImageOnTour(state, tour) {
      const tourIndex = state.tours.findIndex(x => x.uuid === tour.uuid);
      if (!tourIndex === -1) {
        state.tours[tourIndex].image = tour.image;
      }
    },
    clearCreateSuccess(state) {
      state.createSuccess = "";
    },
    clearCurrentTour(state) {
      state.tour = {};
    }
  }
};
