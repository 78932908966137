import Vue from 'vue'
import Vuex from 'vuex'
import authentication from './authentication';
import tours from './tours';
import stories from './stories';
import uploadImage from './uploadimage';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    authentication,
    tours,
    stories,
    uploadImage
  }
})
