import HTTP from "../http";

export default {
  namespaced: true,
  state: {
    successMsg: "",
    stories: [],
    currentStory: {},
    errorBag: {},
    createError: ""
  },
  actions: {
    createStory({ commit, state }) {
      return HTTP()
      .post("/v1/stories/", state.currentStory)
      .then(({data}) => {
        commit("appendStory", data)
        return data
      })
    },
    getStories({commit}) {
      HTTP().get("/v1/stories/")
      .then(({data}) => {
        commit("setStories", data.results);
      })
    },
    deleteStory({ commit }, story) {
      HTTP()
        .delete(`/v1/stories/${story}`)
        .then(() => {
          commit("removeStory", story);
        });
    }
  },
  mutations: {
    setStories(state, stories) {
      state.stories = stories
    },
    removeStory(state, story) {
      state.stories.splice(
        state.stories.findIndex(s => s.uuid === story),
        1
      );
      state.successMsg = "Story Deleted!";
    },
    setStoryDetails(state, details) {
      state.currentStory[details[1]] = details[0];
    },
    setCreateError(state, error) {
      state.errorBag = error;
      state.createError =
        "There was an error creating the story.<br/>Please check all fields were filled out";
    },
    appendStory(state, story) {
      state.stories.push(story);
      state.successMsg = "New story created!";
      state.errorBag = {};
      state.createError = "";
      state.currentStory = story;
    },
    clearSuccessMsg(state) {
      state.successMsg = "";
    },
    clearCurrentStory(state) {
      state.currentStory = {};
    }
  }
}
