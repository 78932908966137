<template>
  <v-container>
    <v-layout class="mb-8">
      <v-row class="">
        <SuccessMsg :msg="successMsg"></SuccessMsg>
        <v-col cols="12" class="d-flex justify-end">
          <v-btn class="" color="blue" dark :to="{ name: 'AddStory' }">
            Add Story
          </v-btn>
        </v-col>
      </v-row>
    </v-layout>
    <v-layout>
      <v-row>
        <Stories></Stories>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
import Stories from "@/components/Stories.vue";
import SuccessMsg from "@/components/SuccessMsg.vue";
import { mapState, mapMutations } from "vuex";

export default {
  components: {
    Stories,
    SuccessMsg
  },
  computed: {
    ...mapState("stories", ["successMsg"])
  },
  methods: {
    ...mapMutations("stories", ["clearSuccessMsg"])
  },
  created() {
    setTimeout(() => {
      this.clearSuccessMsg();
    }, 5000);
  }
};
</script>
