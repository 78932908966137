import HTTP from "../http";
import router from "../router";

export default {
  namespaced: true,
  state: {
    registerError: "",
    registerEmail: "",
    registerEmailError: "",
    registerPassword: "",
    registerPasswordError: "",
    registerConfirmPassword: "",
    registerConfirmPasswordError: "",
    loginError: "",
    loginEmail: "",
    loginEmailError: "",
    loginPassword: "",
    loginPasswordError: "",
    token: localStorage.getItem('access-token') || ""
  },
  actions: {
    register({ commit, state }) {
      return HTTP()
        .post("/auth/register", {
          email: state.registerEmail,
          password: state.registerPassword,
          passwordConfirmation: state.registerConfirmPassword
        })
        .then(d => {
          commit("setToken", d.access_token);
          router.push("/");
        })
        .catch(e => {
          const msg = e.response.data;
          commit("setRegisterError", msg);
        });
    },
    login({ commit, state }) {
      HTTP()
        .post("/auth/login", {
          email: state.loginEmail,
          password: state.loginPassword
        })
        .then(({data}) => {
          commit("setToken", data.access_token);
          router.push("/");
        })
        .catch(e => {
          const msg = e.response.data;
          commit("setLoginError", msg);
        });
    },
    logout({ commit }) {
      commit("setToken", "");
      router.push('/');
    }
  },
  mutations: {
    setToken(state, token) {
      localStorage.setItem('access-token', token);
      state.token = token;
    },
    setRegisterError(state, error) {
      if (typeof error.error === "string") {
        state.registerError = error.error;
      } else {
        state.registerError = "Invalid Registration Information";
      }
      state.registerEmailError = error.error.email;
      state.registerPasswordError = error.error.password;
      state.registerConfirmPasswordError = error.error.passwordConfirmation;
    },
    setRegisterEmail(state, email) {
      state.registerEmail = email;
    },
    setRegisterPassword(state, password) {
      state.registerPassword = password;
    },
    setRegisterConfirmPassword(state, password) {
      state.registerConfirmPassword = password;
    },
    setLoginEmail(state, email) {
      state.loginEmail = email;
    },
    setLoginPassword(state, password) {
      state.loginPassword = password;
    },
    setLoginError(state, error) {
      state.loginEmailError = error.error.email;
      state.loginPasswordError = error.error.password;
      if (typeof error.error === "string") {
        state.loginError = error.error;
      }
    }
  },
  getters: {
    isLoggedIn(state) {
      return !!state.token;
    }
  }
};
