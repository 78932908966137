<template>
  <v-container>
    <v-row>
      <v-col sm="6" offset-sm="3">
        <UploadImage label="Upload Image"></UploadImage>
        <v-alert type="error" :value="!!errorMsg"
          >There was an issue uploading the image. Please try again.</v-alert
        >
        <v-btn color="green" dark @click="clickImageUpload"
          ><v-icon>mdi-plus</v-icon>Upload</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import router from "../router";

export default {
  computed: {
    ...mapState("uploadImage", ["errorMsg"]),
    ...mapState("tours", ["tour"])
  },
  methods: {
    ...mapMutations("uploadImage", ["setErrorMsg"]),
    ...mapMutations("tours", ["clearCurrentTour"]),
    ...mapActions("uploadImage", ["uploadImage"]),
    clickImageUpload() {
      this.uploadImage(`/v1/tours/${this.tour.uuid}`).then(
        () => {
          this.clearCurrentTour();
          router.push("/tours/");
        },
        e => {
          console.log(e);
          this.setErrorMsg(e.response.data);
        }
      );
    }
  }
};
</script>
