<template>
  <v-alert
    transition="slide-y-transition"
    type="success"
    class="ml-auto mr-auto"
    :value="!!msg"
  >
    {{ msg }}
  </v-alert>
</template>

<script>
export default {
  props: {
    msg: String
  }
};
</script>
