<template>
  <v-container>
    <v-row>
      <v-col sm="6" offset-sm="3">
        <h1>Create</h1>
        <v-text-field
          label="Tour Name"
          placeholder="Please enter Tour Name"
          :value="tour['name']"
          @input="setTourDetails([$event, 'name'])"
          :error-messages="errorBag['name']"
        ></v-text-field>
        <v-text-field
          label="Tour Description"
          placeholder="Please enter a short description"
          :value="tour['description']"
          @input="setTourDetails([$event, 'description'])"
          :error-messages="errorBag['description']"
        ></v-text-field>
        <v-text-field
          label="Length"
          placeholder="Please enter tour length"
          :value="tour['length']"
          @input="setTourDetails([$event, 'length'])"
          :error-messages="errorBag['length']"
        ></v-text-field>
        <v-text-field
          label="Tags"
          placeholder="Please enter some tags separated with a comma"
          :value="tour['tags']"
          @input="setTourDetails([$event, 'tags'])"
          :error-messages="errorBag['metaData']"
        ></v-text-field>

        <UploadImage label="Add Tour"></UploadImage>

        <v-alert type="error" :value="!!createError">{{ createError }}</v-alert>
        <v-btn color="green" dark @click="createTourAndUpload"
          ><v-icon>mdi-plus</v-icon>Create</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import router from "../router";

export default {
  computed: {
    ...mapState("tours", ["tour", "createError", "errorBag"]),
  },
  methods: {
    ...mapMutations("tours", [
      "setTourDetails",
      "setImageOnTour",
      "setCreateError"
    ]),
    ...mapMutations("uploadImage", ["setErrorMsg"]),
    ...mapMutations("tours", ["clearCurrentTour"]),
    ...mapActions("tours", ["createTour"]),
    ...mapActions("uploadImage", ["uploadImage"]),

    createTourAndUpload() {
      this.createTour()
        .then(d => {
          this.uploadImage(d["_links"].self)
            .then(() => {
              this.clearCurrentTour();
              router.push("/tours/");
            })
            .catch(e => {
              this.setErrorMsg(e.response.data)
              router.push(`/tours/uploadImage`)
            });
        })
        .catch(e => {
          this.setCreateError(e.response.data);
        });
    }
  }
};
</script>
