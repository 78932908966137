<template>
  <div class="mb-4">
    <v-file-input
      accept="image/*"
      :label="label"
      @change="selectFile"
      :error-messages="errorMsg['error']"
    ></v-file-input>
    <div v-if="currentFile">
      <v-progress-linear
        v-model="progress"
        color="light-green"
        height="25"
        reactive
      >
        <strong>{{ progress }} %</strong>
      </v-progress-linear>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  props: {
    label: String
  },
  computed: {
    ...mapState("uploadImage", ["currentFile", "progress", "errorMsg"])
  },
  methods: {
    ...mapMutations("uploadImage", ["selectFile"])
  }
};
</script>
