import UploadImageService from "../services/UploadImageSerivce";

export default {
  namespaced: true,
  state: {
    currentFile: "",
    progress: 0,
    errorMsg: ""
  },
  actions: {
    uploadImage({ state, commit }, location) {
      if (state.currentFile) {
        return UploadImageService.upload(state.currentFile, location, event => {
          state.progress = Math.round((100 * event.loaded) / event.total);
        }).then(({ data }) => {
          commit("tours/setImageOnTour", data, { root: true });
          commit("resetFileSelect");
        });
      }
    }
  },
  mutations: {
    selectFile(state, file) {
      state.progress = 0;
      state.currentFile = file;
    },
    setErrorMsg(state, error) {
      state.errorMsg = error;
    },
    resetFileSelect(state) {
      state.currentFile = "";
      state.progress = 0;
    }
  }
};
