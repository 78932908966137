import axios from 'axios';
import store from './store';

export default () => {
  return axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    timeout: 5000,
    headers: {
      Authorization: `Bearer ${store.state.authentication.token}`,
      "Content-Type": "application/json"
    },
  });
};
