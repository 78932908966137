import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Register from "../views/Register.vue";
import Login from "../views/Login.vue";
import Tours from "../views/Tours.vue";
import AddTour from "../views/AddTour.vue";
import TourImageUpload from "../views/TourImageUpload.vue";
import StoryImageUpload from "../views/StoryImageUpload.vue";
import Stories from "../views/Stories.vue";
import AddStory from "../views/AddStory.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/register",
    name: "Register",
    component: Register
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/tours",
    name: "Tours",
    component: Tours,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/tours/uploadImage",
    name: "tourImageUpload",
    component: TourImageUpload,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (Object.keys(store.state.tours.tour).length === 0) {
        next({
          name: "Tours"
        });
      } else {
        next();
      }
    }
  },
  {
    path: "/tours/new",
    name: "AddTour",
    component: AddTour,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/stories",
    name: "Stories",
    component: Stories,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/stories/new",
    name: "AddStory",
    component: AddStory,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/stories/uploadImage",
    name: "StoryImageUpload",
    component: StoryImageUpload,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (Object.keys(store.state.stories.currentStory).length === 0) {
        next({
          name: "Stories"
        });
      } else {
        next();
      }
    }
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
