<template>
  <v-card width="100%">
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table :headers="dtHeaders" :items="tours" :search="search">
      <template v-slot:top>
        <v-dialog v-model="deleteDialog" max-width="500px">
          <v-card>
            <v-card-title class="headline"
              >Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteTourConfirm"
                >OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:item.stories="{ item }">
        {{ item.stories.length }}
      </template>
      <template v-slot:item.actions="{ item }">
        <!-- <v-icon small class="mr-2" @click="editTour(item)"> -->
        <!--   mdi-pencil -->
        <!-- </v-icon> -->
        <v-icon small @click="deleteTourPop(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      search: "",
      deleteDialog: false,
      tourToDelete: "",
      dtHeaders: [
        {
          text: "Tour Name",
          align: "start",
          value: "name"
        },
        { text: "uuid", value: "uuid" },
        { text: "Length", value: "length" },
        { text: "Story Count", value: "stories" },
        { text: "Actions", value: "actions", sortable: false }
      ]
    };
  },
  watch: {
    deleteDialog(val) {
      val || this.closeDelete();
    }
  },
  computed: {
    ...mapState("tours", ["tours"])
  },
  methods: {
    ...mapActions("tours", ["getTours", "deleteTour"]),
    deleteTourPop(tour) {
      this.tourToDelete = tour.uuid;
      this.deleteDialog = true;
    },
    deleteTourConfirm() {
      this.deleteTour(this.tourToDelete);
      this.deleteDialog = false;
      this.tourToDelete = "";
    },
    closeDelete() {
      this.deleteDialog = false;
    }
  },
  created() {
    if(this.tours.length === 0) {
      this.getTours();
    }
  }
};
</script>
