<template>
  <v-app-bar color="green" dark fixed>
    <!-- <v-toolbar-side-icon></v-toolbar-side-icon> -->
    <v-toolbar-title class="mr-4">DHAK CMS</v-toolbar-title>
    <v-toolbar-items v-if="isLoggedIn">
      <v-btn text :to="{ name: 'Tours' }">
        <v-icon>mdi-transit-detour</v-icon>Tours
      </v-btn>
      <v-btn text :to="{ name: 'Stories' }">
        <v-icon>mdi-book-open</v-icon>Stories
      </v-btn>
    </v-toolbar-items>
    <v-spacer></v-spacer>
    <v-toolbar-items class="hidden-sm-and-down">
      <v-btn text to="/register" v-if="!isLoggedIn">
        <v-icon>mdi-account-box</v-icon>Register
      </v-btn>
      <v-btn text v-if="!isLoggedIn" :to="{ name: 'Login' }">
        <v-icon>mdi-fingerprint</v-icon>Login
      </v-btn>
      <v-btn text v-if="isLoggedIn" @click="logout">
        <v-icon>mdi-exit-to-app</v-icon>Logout
      </v-btn>
    </v-toolbar-items>
    <v-menu bottom left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn dark icon v-bind="attrs" v-on="on" class="hidden-sm-and-up">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-if="!isLoggedIn">
          <v-btn text to="/register">
            <v-icon>mdi-account-box</v-icon>Register
          </v-btn>
        </v-list-item>
        <v-list-item v-if="!isLoggedIn">
          <v-btn text :to="{ name: 'Login' }">
            <v-icon>mdi-fingerprint</v-icon>Login
          </v-btn>
        </v-list-item>
        <v-list-item v-if="isLoggedIn">
          <v-btn text @click="logout">
            <v-icon>mdi-exit-to-app</v-icon>Logout
          </v-btn>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters("authentication", ["isLoggedIn"])
  },
  methods: {
    ...mapActions("authentication", ["logout"])
  }
};
</script>

<style></style>
